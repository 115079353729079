var setVwoTest = (function ($) {

  return setTest;

  function setTest() {
    var testName = sessionStorage.getItem('lv.test_name');
    var testValue = sessionStorage.getItem('lv.test_value');

    // get the vwo test
    let element = $('#vwo-test');
    let variationValue = element.attr('data-variation');
    console.log('ab-test script running found element as: ', element, ' and variationValue as: ', variationValue);
    if (variationValue) {

      sessionStorage.setItem('lv.test_name', variationValue || 'none');
      sessionStorage.setItem('lv.test_value', Boolean(variationValue));

      testValue = sessionStorage.getItem('lv.test_value');
      testName = sessionStorage.getItem('lv.test_name');
      console.info('New test values are set:', testName, testValue);

    }

    // Fill in the hidden inputs of the product page (if on product page)
    if ($('#test_value')
      .length) {
      $('#test_value')
        .val(testValue);
    }

    if ($('#test_name')
      .length) {
      $('#test_name')
        .val(testName);
    }

    // if (testName == 'proudly-serving' && testValue) {
    //   $('#store_address').addClass('display-none');
    //   $('#store_phone').addClass('display-none');
    //   $('#proudly_serving').removeClass('display-none');
    // }    

    var dataLayer = window.dataLayer = window.dataLayer || [];

    // Dimensions for GTM
    dataLayer.push({
      event: 'lv.abTest',
      attributes: {
        abTest: {
          name: testName,
          value: testValue
        }
      }
    });
  }

})(jQuery);

window.setVwoTest = setVwoTest;