
(function ($) {

  var initialWindowWidth = $(window).width(),
    googleMapsLat = Number($('[data-name="google_maps_lat"]').val()),
    googleMapsLon = Number($('[data-name="google_maps_lon"]').val()),
    googlePlaceId = $('[data-name="google_place_id"]').val(),
    googleMapsCity = $('[data-name="google_maps_city"]').val(),
    googleMapsState = $('[data-name="google_maps_state"]').val(),
    googleMapsCountry = $('[data-name="google_maps_country"]').val(),
    isMapOptout = parseInt($('[data-name="is_map_optout"]').val()),
    isAboutPage = window.location.href.indexOf('about-us') !== -1,
    placesBeenCalled,
    reviewSummaryCalled,
    desktopMapsCalled,
    mobileMapsCalled,
    GOOGLE_API_PUBLIC_KEY = window.storeSettings.GOOGLE_API_PUBLIC_KEY,
    windowMaxWidthMobile = isAboutPage ? 720 : 500,
    isHome = $('#is_home').val() === '1',
    placesService;

  function isMobile() {

    return $(window).width() <= windowMaxWidthMobile;

  }

  // Shrink shop names that are too long;
  if ($('.location-card__title-container h2') && $('.location-card__title-container h2').text().length > 20) {

    $('.location-card__title-container h2').css('font-size', '18px');

  }

  function isGambitTheme() {

    return window.storeSettings && window.storeSettings.theme_id && window.storeSettings.theme_id === 7;

  }

  function renderReviewsCountHtml(reviewCount) {

    // console.log('renderReviewsCountHtml');

    if (isGambitTheme()) {

      return '<a href="/about-us#reviews" aria-label="Lovingly Reviews">(' + reviewCount + ' Lovingly Reviews)</a>';

    }

    return '<span notranslate>(' + reviewCount + ' Lovingly Reviews)</span>';

  }

  function renderReviewsSummary(summary) {

    // Hide the rating container
    $('.location-card--star-rating__container, .location-card--review-number').removeClass('hide');

    $('.location-card--star-rating--rating').rateYo({
      rating: summary.rating,
      starWidth: '13px',
      normalFill: isHome ? '#7095B4' : '#7B7565',
      ratedFill: '#FF8D90',
      numStars: 5,
      spacing: '5px',
      readOnly: true,
      precision: 3
    });

    $('.location-card--star-rating--number').text(summary.rating.toFixed(1));

    if (summary.reviewCount) {

      var reviewsHtml = renderReviewsCountHtml(summary.reviewCount);

      $('.location-card--star-rating--count').html(reviewsHtml);

      if (isAboutPage) {

        // On click of the reviews count, the reviews tab should open
        $('.location-card__title-container.clone div[data-name="lovinglyReviewsNumber"] a').click(function () {

          $('a[href="#reviewsTab"]').click();

        });

      }

    } else {

      $('.location-card--star-rating--count').text('Based on Google Reviews');

    }

  }

  function googlePlaceHandler(place, status) {

    if (status !== window.google.maps.places.PlacesServiceStatus.OK) {
      return;
    }

    if (place && place.rating && place.rating >= 3) {

      renderReviewsSummary(place);

    }

  }

  function getGooglePlaceDetails() {

    if (typeof google === 'undefined') {
      return;
    }

    placesBeenCalled = true;

    // Create Places Service for rating display
    placesService = new google.maps.places.PlacesService(document.createElement('div'));

    // Kill if no place id
    if (!googlePlaceId || !placesService || $('.location-card--star-rating--count').val()) {
      return;
    }

    placesService.getDetails({
      placeId: googlePlaceId,
      fields: ['reviews', 'rating']
    }, googlePlaceHandler);

  }

  function reviewsSummaryHandler(data) {

    if (data && data.summary &&
      data.summary.average_rating > 3 && data.summary.total_count > 1) {

      renderReviewsSummary({
        rating: data.summary.average_rating,
        reviewCount: data.summary.total_count
      });

    } else {

      checkToLoadPlaces();

      $(window).scroll(function () {

        if (placesBeenCalled) {
          return;
        }

        checkToLoadPlaces();

      });

    }

  }

  function checkToLoadPlaces() {

    var reviewElement = $('.location-card--star-rating__container');

    if (!reviewElement.length) {
      return false;
    }

    var docViewTop = $(window).scrollTop();
    var docViewBottom = docViewTop + $(window).height();
    var elemTop = reviewElement.offset().top;
    var elemBottom = elemTop + reviewElement.height();

    if (
      elemBottom <= docViewBottom && elemTop >= docViewTop ||
      elemBottom - reviewElement.height() <= docViewBottom
    ) {

      getGooglePlaceDetails();

      return true;

    }

  }

  function getLovinglyReviewsSummary() {

    if (reviewSummaryCalled || !$('.location-card--star-rating__container, .location-card--review-number').hasClass('hide')) {
      return;
    }

    reviewSummaryCalled = true;

    $.ajax({
        url: '/reviews/summary'
      })
      .done(reviewsSummaryHandler);

  }

  function initMap() {

    if (isAboutPage) {

      callMaps(isMobile() ? 'mobile' : 'desktop');

    } else {

      checkToLoadMaps();

      $(window).scroll(function () {

        checkToLoadMaps();

      });

    }

  }

  function checkToLoadMaps() {

    var mapElementJq = !isMobile() ? $('#map') : $('#mobileMap');

    if (!mapElementJq.length) {
      return false;
    }

    var docViewTop = $(window).scrollTop();
    var docViewBottom = docViewTop + $(window).height();
    var elemTop = mapElementJq.offset().top;
    var elemBottom = elemTop + mapElementJq.height();

    if (
      elemBottom <= docViewBottom && elemTop >= docViewTop ||
      elemBottom - mapElementJq.height() <= docViewBottom
    ) {

      callMaps(isMobile() ? 'mobile' : 'desktop');

    }

  }

  // Create the maps images and iframes
  function callMaps(windowType) {

    var mapElement,
      mapElementMobile;

    getLovinglyReviewsSummary();

    mapElement = document.getElementById('map');
    mapElementMobile = document.getElementById('mobileMap');

    // Kill if no map element or lat or longitude data
    if ((!mapElement && !mapElementMobile) || !googleMapsLat || !googleMapsLon) {
      return;
    }

    return windowType === 'desktop' ? createDesktopMapIframe() : createMobileMapImg();

  }

  function createDesktopMapIframe() {

    // If iframe already exists, return;
    if (desktopMapsCalled || $('#map').attr('src').length) {
      return;
    }

    desktopMapsCalled = true;

    var dataLayer = dataLayer ? dataLayer : [];

    // Datalayer event
    dataLayer.push({
      event: 'lv.homepage.map.loaded'
    });

    var aboutCenter = parseFloat(googleMapsLat + 0.0015) + ',' + parseFloat(googleMapsLon - 0.002);
    var homeCenter = parseFloat(googleMapsLat + 0.0005) + ',' + parseFloat(googleMapsLon - 0.0005);
    var locationQ = '';
    if (isMapOptout) {
      try {
        locationQ = googleMapsCity + ',+' + googleMapsState + ',+' + googleMapsCountry;
      } catch (err) {
        locationQ = googleMapsCity;
      }
    } else {
      locationQ = parseFloat(googleMapsLat) + ',' + parseFloat(googleMapsLon);
    }
    var center = isAboutPage ? aboutCenter : homeCenter;
    var zoom = isMapOptout ? 11 : 17;
    var embeddedMapUrl = 'https://www.google.com/maps/embed/v1/place?key=' + GOOGLE_API_PUBLIC_KEY +
      '&q=' + locationQ +
      '&center=' + center +
      '&zoom=' + zoom;

    $('#map').attr('src', embeddedMapUrl);

  }

  function createMobileMapImg() {

    // If img already exists, return
    if (mobileMapsCalled || $('#mobileMap').attr('src').length) {
      return;
    }

    mobileMapsCalled = true;

    var aboutCenter = parseFloat(googleMapsLat + 0) + ',' + parseFloat(googleMapsLon - 0);
    var homeCenter = parseFloat(googleMapsLat + 0) + ',' + parseFloat(googleMapsLon - 0);
    var locationQ = parseFloat(googleMapsLat) + ',' + parseFloat(googleMapsLon);

    var embeddedMapUrl = 'https://www.google.com/maps/embed/v1/place?key=' + GOOGLE_API_PUBLIC_KEY +
      '&q=' + locationQ +
      '&center=' + (
        isAboutPage ? aboutCenter : homeCenter
      );

    $('#mobileMap').attr('src', embeddedMapUrl);

  }

  $(window).load(function () {

    initMap();

    $(window).bind('resize', function () {

      // If window went from mobile to desktop or vice versa, call maps again with new window type
      if (initialWindowWidth <= 500 && $(window).width() > 500 ||
        initialWindowWidth > 500 && $(window).width() <= 500) {

        callMaps(isMobile() ? 'mobile' : 'desktop');

      }

    });

  });

})(jQuery);
